@import "foundation";

@include foundation-everything($flex: true);

@import 'motion-ui';

@include motion-ui-transitions;
@include motion-ui-animations;

@import "font-awesome";

body * {
	color: #767676;
}

@import "components/buttons",
	"components/elegant";

@import "structure/site/general",
	"structure/clean";

