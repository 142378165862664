.adminButton {
	background: #000;
	border: #000 1px solid;
	color: #fff;
	padding: 5px 10px;

	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;

	&:hover {
		background: #999;
		border: #999 1px solid;
	}
}