.cleanPanel {
	padding-top: 10%;
	margin: 0 auto;
	max-width: 400px;

	header {

	}

	.cleanPanel__body {
		margin: 25px 0;

		h1 {
			font-family: 'Cantarell';
			font-size: rem-calc(18);
			text-transform: uppercase;
		}
	}
}